import constants from './constants';
import utils from './utils';

const widgetCache = {};

const { LOADER_VERSION } = constants;
const {
  getCacheKey,
  getComponent,
  removeScript,
  insertStr,
  findVal,
  addLoaderScript,
  ensureEl,
  createScript,
} = utils;

const createWidgetId = (info, LOADER_VERSION) => {
  const { resp, name, version, id } = info;
  const firstLineBreak = /\r|\n/.exec(resp);
  const minified = firstLineBreak && (firstLineBreak.index > 1);
  const nameVersion = (minified) ? `["${name}"]["${version}"]` : `['${name}']['${version}']`;
  const nameVersionId = (minified) ? `${nameVersion}["${id}"]` : `${nameVersion}['${id}']`;
  const require = `window.sparta.require${nameVersionId}`;
  const widgetParams = `global.spaWidgetParams${nameVersionId}`;
  const widgetRules = `window.sparta.widgetRules${nameVersionId}`;
  const replaceScript = 'require.js';
  const replacementScript = `<script type="text/javascript">
    window.sparta.requireWidget.init({name: '${name}', version: '${version}', id: '${id}'});
  </script>`;
  const moduleOptions = {
    id,
    loader_version: LOADER_VERSION
  };
  const moduleOptsStr = JSON.stringify(moduleOptions);
  let updatedResp = resp;
  const replaceStrings = [
  {
    [`data-sparta-wrapper="${name}-${version}"`]: `data-sparta-wrapper="${name}-${version}-${id}"`
  }, {
    [`data-sparta-container="${name}"`]: `data-sparta-container="${name}" data-id="${id}"`
  }, {
    [`data-module="${name}"`]: `data-module="${name}" data-options='${moduleOptsStr}'`
  }, {
    [`${nameVersion} || {`]: `${nameVersion} || {};\n${require} = ${require} || { \n`
  }, {
    [`${nameVersion}.`]: `${nameVersionId}.`
  }, {
    [`${nameVersion} = {};`]: `${nameVersion} = {};\n\tif (!${widgetParams}) ${widgetParams} = {};`
  }, {
    [`${nameVersion} = [];`]: `${nameVersion} = {}; ${widgetRules} = ${widgetRules} || {}; ${widgetRules} = [];`
  }, {
    [`name: '${name}', version: '${version}'`]: `name: '${name}', version: '${version}', id: '${id}'`
  }, { // minified matches below
    [`${nameVersion}||{`]: `${nameVersion}||{},${require}=${require}||{`
  }, {
    [`${nameVersion}={}),`]: `${nameVersion}={}),e.spaWidgetParams${nameVersionId}||(e.spaWidgetParams${nameVersionId}={}),`
  }, {
    [`${nameVersion}=[],`]: `${nameVersion}={},${widgetRules}=${widgetRules}||{},${widgetRules}=[],`
  }, {
    [`name:"${name}",version:"${version}"`]: `name:"${name}",version:"${version}",id:"${id}"`
  }];
  replaceStrings.forEach(obj => {  // add ID to the output of the AJAX resp by rules above
    const key = Object.keys(obj)[0];
    const strippedKey = key.replace(/[[\]{}()*+?,.\\^$|#]/g, '\\$&');  // cannot have -, \\s,  in the stripping
    if (updatedResp.includes(key)) {
      const regex = RegExp(strippedKey,'gi');
      updatedResp = updatedResp.replace(regex, obj[key]);
    }
  });
  const cleanStrObj = removeScript(replaceScript, updatedResp);
  const cleanedResp = cleanStrObj.string;
  const entryPoint = cleanStrObj.cursor;
  updatedResp = insertStr(cleanedResp, replacementScript, entryPoint);
  return updatedResp;
}

/**
 * Creates and loads a CSS link and appends it to the head of the page.
 * @param {String|Object} data a string or object of CSS resource info
 * @return {undefined}
 */
const loadCSS = (data = {}) => new Promise((resolve, reject) => {
  const element = document.createElement('link');
  const parent = 'head';
  const attr = 'href';
  element.type = 'text/css';
  element.rel = 'stylesheet';

  const { href: url, dataIncludes } = data;
  // Important success and error for the promise
  element.onload = () => resolve(url);
  element.onerror = () => reject(url);

  // Inject into document to kick off loading
  element[attr] = url;
  if (dataIncludes) element.setAttribute('data-includes', dataIncludes);
  document[parent].appendChild(element);
});

/**
 * Creates and loads a Javascript link.
 * @param {String} script a stringified copy of a javascript script
 * @param {String} dest an optional destination for the script to append to
 * @return {undefined}
 */
const loadScript = (script, dest = 'head') => new Promise((resolve, reject) => {
  const jscript = createScript(script);
  jscript.onload = () => resolve(true);
  jscript.error = (err) => reject(`script did not load: ${err}`);
  // Append the script to the head or destination
  if (dest === 'head') {
    document.getElementsByTagName(dest)[0].appendChild(jscript);
  } else {
    dest.appendChild(jscript);
  }

  if (!jscript.src) resolve(true);
});

/**
 * To get Widget Loader Spinnner path.
 * @returns {string} Widget Loader spinner path.
 */
const getWidgetLoaderSpinnerAndCssPath = () => {
  let spinnerLocation = null;
  let styleSheetLocation = null;
  
  return function () {

    if (spinnerLocation && styleSheetLocation) {
      return { spinnerLocation, styleSheetLocation }
    }
  
    const scripts = [].slice.call(document.getElementsByTagName('script') || []);
    const widgetLoaderIndexRegex = '(http|https)://(.)*/spa/widgets/loader/([0-9]+).([0-9]+).([0-9]+)(-alpha)?/index.js';
    const widgetLoaderScripts = scripts.reverse().filter(script => script.src && script.src.match(widgetLoaderIndexRegex))

    if (widgetLoaderScripts && widgetLoaderScripts.length) {
      let currentScriptPath = widgetLoaderScripts[0].src.split('/');
      currentScriptPath[currentScriptPath.length - 1] = 'loading.gif';
      spinnerLocation = currentScriptPath.join('/');

      currentScriptPath =  widgetLoaderScripts[0].src.split('/');
      currentScriptPath[currentScriptPath.length - 1] = 'loader.css';
      styleSheetLocation = currentScriptPath.join('/'); 

      return { spinnerLocation, styleSheetLocation};

    } else {
      console.error('Widget loader script does not exist');
      return false;
    }
  }  
};

/**
 * To get mem cached widget response.
 * @param {string} name Widget name.
 * @param {string} version Widget version.
 * @param {string} id Widget ID
 * @returns {string} Cached Widget response.
 */
function getCachedWidget(name, version, id) {
  const cacheKey = getCacheKey(name, version, id);
  return widgetCache[cacheKey];
}

/**
 * To set mem cached widget response.
 * @param {string} name Widget name.
 * @param {string} version Widget version.
 * @param {string} id Widget ID
 * @returns {undefined} returns nothing
 */
function setCachedWidget(name, version, id, obj) {
  const cacheKey = getCacheKey(name, version, id);
  widgetCache[cacheKey] = { ...widgetCache[cacheKey], ...obj };
}

/**
 * To remove widget response from mem cache.
 * @param {string} name Widget name.
 * @param {string} version Widget version.
 * @param {string} id Widget ID
 * @returns {boolean} True/False for deletion of an object from cache.
 */
function cleanCachedWidget(name, version, id) {
  const cacheKey = getCacheKey(name, version, id);
  delete widgetCache[cacheKey];
}

const subscriptionPrototype = {
  pub: function (eventName, detail = {}) { // creates and dispatches a native CustomEvent on our DispatcherElement
    if (!detail.widget || detail.widget.id === this.dispatcherElement.id) {
      const evt = new CustomEvent(eventName, { detail });
      this.dispatcherElement.dispatchEvent(evt);
    }
  },
  sub: function (eventName, callback) { //attaches an event listener to our DispatcherElement
    this.eventCollection.push({type: 'widget', name: eventName, fn: callback})
    this.dispatcherElement.addEventListener(eventName, callback);
  },
  unsub: function (eventName, callback) { // allows subscriber to unsubscribe from event
    this.dispatcherElement.removeEventListener(eventName, callback);
  },
  once: function (eventName, callback) { //allows subscriber to listen to one instance of event and then unsub
    const fn = (e) => {
      this.dispatcherElement.removeEventListener(eventName, fn);
      callback(e);
    };
    this.dispatcherElement.addEventListener(eventName, fn);
  }
};

const eventsPrototype = (id) => {
  const dispatcherElement = { dispatcherElement: id };
  const eventCollection = { eventCollection: [] };
  return Object.assign({}, subscriptionPrototype, dispatcherElement, eventCollection);
};

const addNamespaceClass = (info) => {
  const { name, version, id } = info;
  const spartaWrapperEl = document.querySelector(`[data-sparta-wrapper="${name}-${version}-${id}"]`);
  const dashedVersion = version.replace(/\./g, '-');
  const nameVersionNamespace = `${name}-${dashedVersion}`;
  spartaWrapperEl.classList.add(nameVersionNamespace);
};

const loadWidget = (config, oldVersion = null) => {
  const { name, version, id } = config;
  // hijack require and define definitions and add them to a widget instance
  window.sparta.require[name][version][id].require = window.sparta.require[name][version].require;
  window.sparta.require[name][version][id].define = window.sparta.require[name][version].define;
  window.sparta.require[name][version][id].load();
  if (oldVersion) {
    window.sparta.require[name][oldVersion] = window.sparta.require[name][version];
  }
};

/**
 * @method createWidgetEvents
 * @param {Object|undefined} widgets an object of widgets or not
 * @param {Object} container dom object of the dispatch container
 * @returns {undefined} sets events
 */
function createWidgetEvents(container = '', widgets = false) {
  const proto = Object.create(eventsPrototype(container)); // a new widgets event obj for lateral widget communication
  if (widgets) return widgets.events = proto;
  return proto;
}

/**
 * @method refreshWidget
 * @description refreshes widget with new data
 * @param {Object} config for the widget
 * @param {Object} data new data for the widget
 * @returns {Null} returns nothing
 */
const refreshWidget = (config, data = {}) => {
  if (!config.options) config.options = {};
  const { name, version, container: id, loaderVersion, options } = config;
  if (!options.data) options.data = {};
  options.data = data;
  const updatedConfig = Object.assign({}, config, { enableWidgetRefresh: false, enableWidgetCache: true });
  const widgetLoader = window.sparta.widgets[loaderVersion][name][version][id];
  widgetLoader.load(updatedConfig);
  return widgetLoader;
};

/**
 * @method destroyWidget
 * @description destroys a widget on a given page
 * @param {Object} config for the widget
 * @returns {Null} returns nothing
 */
const destroyWidget = (config) => {
  const { name, version, container: id, loaderVersion } = config;

  if (window.sparta.require
    && window.sparta.require[name]
    && window.sparta.require[name][version]
    && window.sparta.require[name][version][id]) {
    const requireWidget = window.sparta.require[name][version][id];
    const widget = window.sparta.widgets[loaderVersion][name][version][id];
    const widgetEvents = widget.events;
    const dispatcherEl = widgetEvents.dispatcherElement;
    widgetEvents.eventCollection.forEach((widgetEvent) => {
      const { name: eventName, fn, type } = widgetEvent;
      if (type === 'sparta') {
        getComponent('js/sparta-events', requireWidget).unsubByEvent(eventName);
      } else if (type === 'widget') {
        getComponent('js/sparta-widget-events', requireWidget).unsub(eventName, fn);
      } else {
        dispatcherEl.removeEventListener(eventName, fn);
      }
    });
    widgetEvents.eventCollection.length = 0;
    delete window.sparta.require[name][version][id];
  }

  const widget = document.querySelector(`#${id}`);
  widget.innerHTML = '';
  const widgetClone = widget.cloneNode(true);
  widget.parentNode.replaceChild(widgetClone, widget);

  widgetClone.classList.remove('sparta-widget-loading');
};

export default {
  addNamespaceClass,
  createWidgetId,
  getCachedWidget,
  setCachedWidget,
  cleanCachedWidget,
  loadCSS,
  loadScript,
  loadWidget,
  getWidgetLoaderSpinnerAndCssPath,
  createWidgetEvents,
  refreshWidget,
  destroyWidget,
};
