import './smallPolyfill';
import 'custom-event-polyfill';
import './requireWidget';
import constants from './constants';
import WidgetLoaderClass from './WidgetLoader';
import utils from './utils';
import internalFunctions from './internalFunctions';

const { LOADER_VERSION } = constants;

const {
  ensureEl,
  findVal,
  getWidget,
  setWidget,
  widgetExists,
} = utils;

const {
  getWidgetLoaderSpinnerAndCssPath,
  refreshWidget,
  destroyWidget,
} = internalFunctions;

/**
 * @method addWidget
 * @description adds a widget loader and its accompanying script to the page
 * @param {Object} config object of the widget configuration
 * @param {Array} widgets an array of widgets
 * @returns {Null} returns nothing
 */
const addWidget = (config, widgets) => {
  const { name, version, container } = config;
  const { id } = ensureEl(container);
  if (config.loaderVersion.charAt(0) >= 4) {
    const widgetLoader = new WidgetLoaderClass(id);
    if (!widgets[name]) {
      widgets[name] = { [version]: { [id]: widgetLoader }};
    } else if (!widgets[name][version]) {
      widgets[name][version] = { [id]: widgetLoader };
    } else {
      widgets[name][version][id] = widgetLoader;
    }
    const widgetInstance = widgets[name][version][id];
    setWidget(widgetInstance);
    widgetInstance.refresh = (data) => refreshWidget(config, data);
    widgetInstance.destroy = () => destroyWidget(config);
  } else { // kick off old sparta widgets
    const loader = addLoaderScript(config.path, config.loaderVersion);
    loader.dataset.widgetName = name;
    loader.dataset.widgetVersion = version;
    document.body.appendChild(loader);
  }
};

/**
 * @method initializeWidgets
 * @description initialization of the primary widget loader
 * @param {Object} sparta global object to namespace our widget goodies
 * @returns {Object} returns the widget loader object
 */
const initializeWidgets = (sparta) => {
  const widgetPrototype = {
    // events: createWidgetEvents(),
    load: function(widgetConfig) {
      const { name, version, container: id, loaderVersion, onload } = widgetConfig;
      const widget = widgetExists({ name, version, id, loaderVersion });
      if (widget) widget.destroy();
      addWidget(widgetConfig, this);
      if (loaderVersion >= LOADER_VERSION) {
        const loader = getWidget();
        loader.load(widgetConfig);
        if (onload && onload.constructor === Function) onload(loader);
        return loader;
      }
    }
  };
  const loaderAssetPaths = getWidgetLoaderSpinnerAndCssPath();
  if (!sparta.widgets) {
    sparta.widgets = {};
    sparta.widgets = Object.create({
      get: (id) => findVal(sparta.widgets, id),
      spinnerLocation: loaderAssetPaths().spinnerLocation,
      styleSheetLocation: loaderAssetPaths().styleSheetLocation
    });
  }
  sparta.widgets[LOADER_VERSION] = Object.create(widgetPrototype);
};

window.sparta = window.sparta || {};

const { sparta } = window;

if (!sparta.widgets || (sparta.widgets && !sparta.widgets[LOADER_VERSION])) initializeWidgets(sparta);

document.dispatchEvent(new CustomEvent('spartaLoaderInitialized'));
